<template>
  <div class="medicine-edit">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="title">薬剤物品編集</div>
      <div class="contents">
        <div class="content-wrap">
          <div class="heading">基本項目</div>
          <div class="content">
            <medicine-input-form
              class="input-form"
              :largeClass="largeClass"
              :mediumClass="mediumClass"
              :smallClass="smallClass"
              :medicine="medicine"
              :vaccineTypeId="vaccineTypeId"
              @text-input="handleTextInput"
            />
          </div>
        </div>
        <div class="button-wrap">
          <base-button-medium-white class="button" @click="goToShowPage"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || waitFlg"
            @click="updateData"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <announce-popup
        v-if="popupFlg"
        :title="title"
        :buttons="buttons"
        :type="type"
        @close="closePopup"
        >{{ popupMessage }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import MedicineInputForm from '@/components/parts/organisms/MedicineInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import SlideListRoute from '@/components/mixins/SlideListRoute'

export default {
  name: 'MedicineEdit',

  components: {
    MedicineInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference, SlideListRoute],

  props: {
    originalId: { type: Number }
  },

  data() {
    return {
      largeClass: {},
      mediumClass: {},
      smallClass: {},
      medicine: {},
      vaccineTypeId: 0,
      waitFlg: false,
      outdatedFlg: false,
      noDataFlg: false,
      popupFlg: false,
      type: '',
      title: '',
      buttons: ['閉じる'],
      popupMessage: ''
    }
  },

  computed: {
    ...mapGetters({
      getLargeClass: 'medicineLargeClasses/getDataByOriginalId',
      getMediumClass: 'medicineMediumClasses/getDataByOriginalId',
      getSmallClass: 'medicineSmallClasses/getDataByOriginalId',
      getMedicine: 'medicines/getDataByOriginalId',
      getMasterDatum: 'master/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },

  created() {
    this.medicine = { ...this.getMedicine(this.originalId) }
    this.smallClass = {
      ...this.getSmallClass(this.medicine.medicineSmallClassOriginalId)
    }
    this.mediumClass = {
      ...this.getMediumClass(this.smallClass.medicineMediumClassOriginalId)
    }
    this.largeClass = {
      ...this.getLargeClass(this.mediumClass.medicineLargeClassOriginalId)
    }
    this.mixinInputData = {
      largeClass: this.largeClass,
      mediumClass: this.mediumClass,
      smallClass: this.smallClass,
      medicine: this.medicine
    }
    this.mixinSetInitialData()
    this.vaccineTypeId = this.makeVaccineTypeId(this.medicine)
  },

  methods: {
    makeVaccineTypeId(medicine) {
      const vaccine = this.getMasterDatum('vaccines', medicine.vaccineId)
      return vaccine?.dogFlg === 1 ? 1 : vaccine?.catFlg === 1 ? 2 : 0
    },
    goToListPage() {
      const medicine = this.getMedicine(this.originalId)
      const query = this.mixinMakeMedicineRouteQuery(
        'small',
        medicine.medicineSmallClassOriginalId
      )
      this.$router.push({ name: 'medicines', query })
    },
    goToShowPage() {
      this.$router.push({ name: 'medicine-show', params: this.originalId })
    },
    async updateData() {
      this.waitFlg = true
      const result = await this.$store.dispatch('medicines/update', {
        medicineLargeClass: this.largeClass,
        medicineMediumClass: this.mediumClass,
        medicineSmallClass: this.smallClass,
        medicine: this.medicine
      })
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '編集しました'
        this.mixinSetInitialData()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        if (result === 'already edited') {
          this.popupMessage =
            '編集に失敗しました。\n編集中の薬剤物品は別端末で編集されました。'
          this.outdatedFlg = true
        } else if (result === 'already deleted') {
          this.popupMessage =
            '編集に失敗しました。\n編集中の薬剤物品は既に削除されています。'
          this.noDataFlg = true
        } else if (result === 'no data in clinic') {
          this.popupMessage =
            '編集に失敗しました。\n薬剤物品は既に削除されています。'
          this.noDataFlg = true
        } else {
          this.popupMessage = '編集に失敗しました'
        }
      }
      this.waitFlg = false
      this.popupFlg = true
    },
    closePopup() {
      this.popupFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.goToShowPage()
      } else if (this.outdatedFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.goToShowPage()
      } else if (this.noDataFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.goToListPage()
      }
      this.title = ''
      this.type = ''
      this.popupMessage = ''
    },
    handleTextInput(e) {
      if (e.field === 'vaccineTypeId') {
        this.medicine.vaccineId = 0
        this.vaccineTypeId = e.val
      } else {
        this.medicine[e.field] = e.val
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.medicine-edit {
  width: 100%;
  text-align: left;
  > .validation-observer {
    > .title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
      width: 100%;
      min-width: 700px;
    }
    > .contents {
      margin-top: 20px;
      padding-bottom: 50px;
      > .content-wrap {
        > .heading {
          font-size: 15px;
          font-weight: bold;
          border-bottom: 1px solid #{$light-grey};
          padding-bottom: 20px;
          min-width: 700px;
        }
        > .content {
          margin-top: 25px;
          > .input-form {
            width: 600px;
          }
        }
      }
      > .button-wrap {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
