<template>
  <div class="medicine-input-form">
    <div class="row medicine-large-class">
      <div class="text-display" v-if="largeClassesSelectData.length === 0">
        <div class="heading">大分類名</div>
        <div class="body">{{ largeClass.name }}</div>
      </div>
      <select-box-text-form
        v-else
        :label="'大分類名'"
        :maxlength="30"
        :placeholder="'30文字まで'"
        :requiredFlg="true"
        :selectData="largeClassesSelectData"
        :selectStyles="{ width: '200px' }"
        :selectValue="largeClass.id"
        :textDisabled="Boolean(largeClass.id)"
        :textStyles="{ width: '250px' }"
        :textValue="largeClass.name"
        @select-input="selectInput($event, 'largeClass')"
        @text-input="textInput($event, 'largeClass')"
      />
    </div>
    <div class="row medicine-class">
      <div class="text-display" v-if="mediumClassesSelectData.length === 0">
        <div class="heading">中分類名</div>
        <div class="body">{{ mediumClass.name }}</div>
      </div>
      <select-box-text-form
        v-else
        :label="'中分類名'"
        :maxlength="30"
        :placeholder="'30文字まで'"
        :requiredFlg="true"
        :selectData="mediumClassesSelectData"
        :selectStyles="{ width: '200px' }"
        :selectValue="mediumClass.id"
        :textDisabled="Boolean(mediumClass.id)"
        :textStyles="{ width: '250px' }"
        :textValue="mediumClass.name"
        @select-input="selectInput($event, 'mediumClass')"
        @text-input="textInput($event, 'mediumClass')"
      />
    </div>
    <div class="row small-class">
      <div class="text-display" v-if="smallClassesSelectData.length === 0">
        <div class="heading">小分類名</div>
        <div class="body">{{ smallClass.name }}</div>
      </div>
      <select-box-text-form
        v-else
        :label="'小分類名'"
        :maxlength="50"
        :placeholder="'50文字まで'"
        :requiredFlg="true"
        :selectData="smallClassesSelectData"
        :selectStyles="{ width: '200px' }"
        :selectValue="smallClass.id"
        :textDisabled="Boolean(smallClass.id)"
        :textStyles="{ width: '250px' }"
        :textValue="smallClass.name"
        @select-input="selectInput($event, 'smallClass')"
        @text-input="textInput($event, 'smallClass')"
      />
    </div>
    <div class="row medicine name">
      <text-input-form
        :maxlength="50"
        :placeholder="'50文字まで'"
        :requiredFlg="true"
        :styles="{ width: '350px' }"
        :value="medicine.name"
        @input="textInput($event, 'name')"
        >薬剤物品名</text-input-form
      >
    </div>
    <div class="row medicine price-rate">
      <text-input-form
        :maxlength="7"
        :styles="{ width: '350px' }"
        :validationRules="{ intRule: true }"
        :value="medicine.priceRate"
        :fullWidthNumbersToHalfWidthNumbers="true"
        @input="textInput($event, 'priceRate')"
        @blur="blur('priceRate')"
        >単価（￥）</text-input-form
      >
    </div>
    <div class="row medicine default-volume">
      <text-input-form
        :maxlength="5"
        :styles="{ width: '350px' }"
        :validationRules="{ floatRule: true }"
        :value="medicine.defaultVolume"
        :fullWidthNumbersToHalfWidthNumbers="true"
        @input="textInput($event, 'defaultVolume')"
        @blur="blur('defaultVolume')"
        >初期値</text-input-form
      >
    </div>
    <div class="row medicine unit-type-id">
      <select-box-form
        :selectData="unitTypesSelectData"
        :value="medicine.unitTypeId"
        @input="textInput($event, 'unitTypeId')"
        >単位</select-box-form
      >
    </div>
    <div class="row medicine tax-exempt-flg">
      <select-box-form
        :selectData="taxExemptSelectData"
        :value="medicine.taxExemptFlg"
        @input="textInput($event, 'taxExemptFlg')"
        >非課税</select-box-form
      >
    </div>
    <div class="row medicine memo">
      <multiple-text-input-form
        :maxlength="255"
        :styles="{ width: '350px', height: '66px' }"
        :value="medicine.memo"
        @input="textInput($event, 'memo')"
        >メモ</multiple-text-input-form
      >
    </div>
    <div class="row medicine anesthesia-flg">
      <select-box-form
        :selectData="anesthesiaSelectData"
        :value="medicine.anesthesiaFlg"
        @input="textInput($event, 'anesthesiaFlg')"
        >麻酔薬</select-box-form
      >
    </div>
    <div class="row medicine anicom-flg">
      <select-box-form
        :selectData="anicomSelectData"
        :value="medicine.anicomFlg"
        @input="textInput($event, 'anicomFlg')"
        >アニコム保険</select-box-form
      >
    </div>
    <div class="row medicine ipet-flg">
      <select-box-form
        :selectData="ipetSelectData"
        :value="medicine.ipetFlg"
        @input="textInput($event, 'ipetFlg')"
        >アイペット保険</select-box-form
      >
    </div>
    <div class="row medicine vaccine-id">
      <vaccine-input-form
        :selectData="vaccineTypesSelectData"
        :vaccineTypeId="vaccineTypeId"
        :vaccineId="medicine.vaccineId"
        @select="textInput($event, 'vaccineTypeId')"
        @check="textInput($event, 'vaccineId')"
        >ワクチン</vaccine-input-form
      >
    </div>
    <div class="row medicine show-flg">
      <select-box-form
        :selectData="showSelectData"
        :value="medicine.showFlg"
        @input="textInput($event, 'showFlg')"
        >表示設定</select-box-form
      >
    </div>
  </div>
</template>

<script>
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm.vue'
import SelectBoxTextForm from '@/components/parts/molecules/SelectBoxTextForm.vue'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import MultipleTextInputForm from '@/components/parts/molecules/MultipleTextInputForm'
import VaccineInputForm from '@/components/parts/molecules/VaccineInputForm'
import { mapGetters } from 'vuex'
import { isNumber } from '@/utils/convert_string'

export default {
  name: 'MedicineInputForm',

  components: {
    SelectBoxForm,
    SelectBoxTextForm,
    TextInputForm,
    MultipleTextInputForm,
    VaccineInputForm
  },

  props: {
    largeClass: { type: Object, required: true },
    largeClassesSelectData: { type: Array, default: () => [] },
    mediumClass: { type: Object, required: true },
    mediumClassesSelectData: { type: Array, default: () => [] },
    smallClass: { type: Object, required: true },
    smallClassesSelectData: { type: Array, default: () => [] },
    medicine: { type: Object, required: true },
    vaccineTypeId: { type: [String, Number], default: 0 }
  },

  data() {
    return {
      taxExemptSelectData: [],
      anesthesiaSelectData: [],
      anicomSelectData: [],
      ipetSelectData: [],
      disabled: false,
      errorShowFlg: false,
      showSelectData: [],
      vaccineTypesSelectData: [
        { id: 0, name: '非ワクチン' },
        { id: 1, name: '犬ワクチン' },
        { id: 2, name: '猫ワクチン' }
      ]
    }
  },

  computed: {
    ...mapGetters({
      getMasterData: 'master/getData'
    }),
    unitTypesSelectData() {
      return this.getMasterData('unitTypes')
    }
  },

  created() {
    const circleCrossSelectData = [
      { id: 0, name: '✕' },
      { id: 1, name: '〇' }
    ]
    this.taxExemptSelectData = circleCrossSelectData
    this.anesthesiaSelectData = circleCrossSelectData
    this.anicomSelectData = circleCrossSelectData
    this.ipetSelectData = circleCrossSelectData
    this.showSelectData = circleCrossSelectData
  },

  methods: {
    selectInput(val, field) {
      this.$emit('select-input', { val, field })
    },
    textInput(val, field) {
      if (field === 'priceRate' || field === 'defaultVolume') {
        const numVal = val === '' ? '' : Number(val)
        val = isNumber(numVal) ? numVal : val
      }
      this.$emit('text-input', { val, field })
    },
    blur(field) {
      if (this.medicine[field] === '') this.medicine[field] = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.medicine-input-form {
  > .row {
    margin-bottom: 30px;
    > .text-display {
      display: flex;
      align-items: flex-start;
      line-height: 33px;
      > .heading {
        width: 40%;
        font-size: 15px;
      }
      > .body {
        width: 60%;
        font-size: 13px;
      }
    }
  }
}
</style>
