// SlideListRoute

export default {
  methods: {
    mixinMakeMedicineRouteQuery(layer, originalId) {
      const query = {}
      if (layer === 'large') {
        query.lc = originalId
      } else if (layer === 'medium') {
        const medicineMediumClass = this.$store.getters[
          'medicineMediumClasses/getDataByOriginalId'
        ](originalId)
        query.lc = medicineMediumClass.medicineLargeClassOriginalId
        query.mc = originalId
      } else if (layer === 'small') {
        const medicineSmallClass = this.$store.getters[
          'medicineSmallClasses/getDataByOriginalId'
        ](originalId)
        const medicineMediumClass = this.$store.getters[
          'medicineMediumClasses/getDataByOriginalId'
        ](medicineSmallClass.medicineMediumClassOriginalId)
        query.lc = medicineMediumClass.medicineLargeClassOriginalId
        query.mc = medicineSmallClass.medicineMediumClassOriginalId
        query.sc = originalId
      }
      return query
    },
    mixinMakeTreatmentRouteQuery(layer, originalId) {
      const query = {}
      if (layer === 'largeClass') {
        query.lc = originalId
      } else if (layer === 'class') {
        const treatmentClass = this.$store.getters[
          'treatmentClasses/getDataByOriginalId'
        ](originalId)
        query.lc = treatmentClass.treatmentLargeClassOriginalId
        query.c = originalId
      }
      return query
    }
  }
}
