<template>
  <div class="medicines">
    <div class="title">薬剤物品</div>
    <div class="content">
      <div class="area buttons" v-if="!sortModeFlg">
        <base-button-register :text="'項目追加'" @click="goToNewPage" />
        <base-sort-button
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="setSortMode"
        />
      </div>
      <div class="area buttons" v-else>
        <base-button-small-white class="button" @click="cancelSortMode"
          >キャンセル</base-button-small-white
        >
        <base-button-small-orange
          class="button"
          v-if="lookOnlyFlg === 0"
          :disabled="waitFlg"
          @click="updateOrder"
          >並び順登録</base-button-small-orange
        >
      </div>
      <div class="area list">
        <slide-list
          ref="slideList"
          :bodyData="displayData"
          :bottomLayer="bottomLayer"
          :sortModeFlg="sortModeFlg"
          @order-parents="orderParents"
          @order-children="orderChildren"
          @order-grandchildren="orderGrandchildren"
          @order-great-grandchildren="orderGreatGrandchildren"
          @add-child="goToNewPage('large', $event)"
          @add-grandchild="goToNewPage('medium', $event)"
          @add-great-grandchild="goToNewPage('small', $event)"
          @delete-parent="openDeletePopup('medicineLargeClasses', $event)"
          @delete-child="openDeletePopup('medicineMediumClasses', $event)"
          @delete-grandchild="openDeletePopup('medicineSmallClasses', $event)"
          @edit-parent="goToEditPage('medicine-large-classes', $event)"
          @edit-child="goToEditPage('medicine-medium-classes', $event)"
          @edit-grandchild="goToEditPage('medicine-small-classes', $event)"
          @select-great-grandchild="goToShowPage"
        />
      </div>
    </div>
    <announce-popup
      v-if="popup.showFlg"
      :type="popup.type"
      :title="popup.title"
      :buttons="popup.buttons"
      :disabled="waitFlg"
      @close="closePopup"
      @cancel="popup.cancel"
      @decision="popup.decision"
      >{{ popup.message }}</announce-popup
    >
    <unsaved-leave-popup />
  </div>
</template>

<script>
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import BaseSortButton from '@/components/parts/atoms/BaseSortButton'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import SlideList from '@/components/parts/organisms/SlideList'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'Medicines',

  components: {
    BaseButtonRegister,
    BaseSortButton,
    BaseButtonSmallWhite,
    BaseButtonSmallOrange,
    SlideList,
    AnnouncePopup,
    UnsavedLeavePopup
  },

  mixins: [CheckInputDifference],

  data() {
    return {
      bottomLayer: 'greatGrandchildren',
      waitFlg: false,
      popup: {},
      deletePopup: {
        showFlg: true,
        type: 'alert',
        title: '注意',
        buttons: ['削除しない', '削除する'],
        message: '削除してもよろしいですか？',
        cancel: () => {
          this.closePopup()
        }
      },
      sortModeFlg: false
    }
  },

  computed: {
    ...mapGetters({
      largeClasses: 'medicineLargeClasses/getData',
      mediumClassesByLargeOriginalId:
        'medicineMediumClasses/getDataByMedicineLargeClassOriginalId',
      smallClassesByMediumOriginalId:
        'medicineSmallClasses/getDataByMedicineMediumClassOriginalId',
      medicinesBySmallOriginalId:
        'medicines/getDataByMedicineSmallClassOriginalId',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    displayData() {
      return this.sortModeFlg
        ? this.mixinInputData
        : this.largeClasses.map(largeClass => {
            const parent =
              largeClass.defaultMedicineLargeClassId === 0
                ? this.makeListDatum(largeClass)
                : this.makeListDatum(largeClass, true)
            const mediumClasses = this.mediumClassesByLargeOriginalId(
              largeClass.originalId
            )
            parent.children = mediumClasses
              ? mediumClasses.map(mediumClass => {
                  const child = this.makeListDatum(mediumClass)
                  const smallClasses = this.smallClassesByMediumOriginalId(
                    mediumClass.originalId
                  )
                  child.grandchildren = smallClasses
                    ? smallClasses.map(smallClass => {
                        const grandchild = this.makeListDatum(smallClass)
                        const medicines = this.medicinesBySmallOriginalId(
                          smallClass.originalId
                        )
                        grandchild.greatGrandchildren = medicines || []
                        return grandchild
                      })
                    : []
                  return child
                })
              : []
            return parent
          })
    }
  },

  created() {
    this.initializePopup()
  },

  mounted() {
    this.openAccordion()
  },

  methods: {
    initializePopup() {
      this.popup = {
        showFlg: false,
        type: '',
        title: '',
        buttons: [],
        message: '',
        cancel: () => {},
        decision: () => {}
      }
    },
    makeListDatum(datum, noChangeFlg = false, plusFlg = true) {
      return { ...datum, noChangeFlg, plusFlg }
    },
    setSortMode() {
      this.mixinInputData = _.cloneDeep(this.displayData)
      this.mixinSetInitialData()
      this.sortModeFlg = true
    },
    cancelSortMode() {
      this.mixinInputData = _.cloneDeep(this.displayData)
      this.mixinSetInitialData()
      this.sortModeFlg = false
    },
    orderParents(newOrderParents) {
      this.mixinInputData = newOrderParents
    },
    orderChildren(data) {
      this.$set(
        this.mixinInputData[data.parentIndex],
        'children',
        data.newOrderChildren
      )
    },
    orderGrandchildren(data) {
      this.$set(
        this.mixinInputData[data.parentIndex].children[data.childIndex],
        'grandchildren',
        data.newOrderGrandchildren
      )
    },
    orderGreatGrandchildren(data) {
      this.$set(
        this.mixinInputData[data.parentIndex].children[data.childIndex]
          .grandchildren[data.grandchildIndex],
        'greatGrandchildren',
        data.newOrderGreatGrandchildren
      )
    },
    async updateOrder() {
      this.waitFlg = true
      const idObj = {
        largeClassesOrderIds: [],
        mediumClassesOrderIds: [],
        smallClassesOrderIds: [],
        orderIds: []
      }
      this.mixinInputData.forEach(v => {
        v.children.forEach(x => {
          x.grandchildren.forEach(y => {
            y.greatGrandchildren.forEach(z => {
              idObj.orderIds.push(z.id)
            })
            idObj.smallClassesOrderIds.push(y.id)
          })
          idObj.mediumClassesOrderIds.push(x.id)
        })
        idObj.largeClassesOrderIds.push(v.id)
      })
      const res = await this.$store.dispatch(
        'medicineLargeClasses/order',
        idObj
      )
      if (res) {
        this.mixinSetInitialData()
        this.popup.type = 'success'
        this.popup.title = '完了'
        this.popup.message = '並び順を登録しました'
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        this.popup.message = '並び順登録に失敗しました'
      }
      this.popup.buttons = ['閉じる']
      this.popup.showFlg = true
      this.waitFlg = false
    },
    goToNewPage(from, id) {
      this.$router.push({ name: 'medicine-new', params: { from, id } })
    },
    goToEditPage(target, originalId) {
      this.$router.push({ path: `medicines/${target}/${originalId}/edit` })
    },
    goToShowPage(originalId) {
      this.$router.push({ name: 'medicine-show', params: { originalId } })
    },
    openDeletePopup(storeName, id) {
      this.popup = {
        ...this.deletePopup,
        decision: () => this.delete(storeName, id)
      }
    },
    async delete(storeName, id) {
      this.waitFlg = true
      const res = await this.$store.dispatch(`${storeName}/delete`, id)
      if (res === true) {
        this.popup.type = 'success'
        this.popup.title = '完了'
        this.popup.message = '削除しました'
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        this.popup.message =
          res === 'used in treatmentSetItem'
            ? '診療項目セットで使用されているため削除できません'
            : res === 'used in estimateTreatmentItem'
            ? '見積書で使用されているため削除できません'
            : '削除に失敗しました'
      }
      this.popup.buttons = ['閉じる']
      this.popup.showFlg = true
      this.waitFlg = false
    },
    closePopup() {
      this.initializePopup()
      if (this.sortModeFlg) {
        this.cancelSortMode()
      }
    },
    openAccordion() {
      if (this.$route.query.lc) {
        const largeRef = `parent${this.$route.query.lc}`
        const large = this.$refs.slideList.$refs[largeRef]
        if (large) {
          large[0].toggleChild()
          if (this.$route.query.mc) {
            const mediumRef = `child${this.$route.query.mc}`
            const medium = large[0].$refs[mediumRef]
            if (medium) {
              medium[0].clickGrandchild()
              if (this.$route.query.sc) {
                const smallRef = `grandchild${this.$route.query.sc}`
                const small = medium[0].$refs[smallRef]
                if (small) {
                  small[0].clickGrandchild()
                }
              }
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.medicines {
  width: 100%;
  min-width: 614px;
  text-align: left;
  > .title {
    padding-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #{$light-grey};
  }
  > .content {
    margin-top: 20px;
    padding-bottom: 50px;
    > .area {
      &.buttons {
        display: flex;
        justify-content: flex-end;
        > .button {
          margin-left: 20px;
        }
      }
      &.list {
        margin-top: 26px;
      }
    }
  }
}
</style>
