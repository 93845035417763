<template>
  <div class="medicine-show">
    <div class="list-link">
      <base-back-link @click="goToListPage" />
    </div>
    <div class="title">薬剤物品詳細</div>
    <div class="contents">
      <div class="button-area">
        <base-button-small-white
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="goToEditPage"
          >編集</base-button-small-white
        >
        <base-button-small-red
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="openDeletePopup"
          >削除</base-button-small-red
        >
      </div>
      <div class="basic-items-area">
        <div class="heading">基本項目</div>
        <div class="basic-items">
          <show-data-list
            :displayKeys="displayKeys"
            :keys="keys"
            :data="displayData"
          />
        </div>
      </div>
    </div>
    <announce-popup
      v-if="popupFlg"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      :type="type"
      @cancel="cancelDelete"
      @decision="decideDelete"
      @close="closePopup"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import ShowDataList from '@/components/parts/organisms/ShowDataList.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import { toCircleOrCross, toZeroSuppress } from '@/utils/convert_string'
import SlideListRoute from '@/components/mixins/SlideListRoute'

export default {
  name: 'MedicineShow',

  components: {
    BaseBackLink,
    BaseButtonSmallWhite,
    BaseButtonSmallRed,
    ShowDataList,
    AnnouncePopup
  },

  mixins: [SlideListRoute],

  props: {
    originalId: { type: Number }
  },

  data() {
    return {
      displayKeys: [
        '大分類名',
        '中分類名',
        '小分類名',
        '薬剤物品名',
        '単価（￥）',
        '初期値',
        '単位',
        '非課税',
        'メモ',
        '麻酔薬',
        'アニコム保険',
        'アイペット保険',
        'ワクチン',
        '表示設定'
      ],
      keys: [
        'largeClassName',
        'mediumClassName',
        'smallClassName',
        'name',
        'priceRate',
        'defaultVolume',
        'unitType',
        'taxExemptFlg',
        'memo',
        'anesthesiaFlg',
        'anicomFlg',
        'ipetFlg',
        'vaccine',
        'showFlg'
      ],
      popupFlg: false,
      waitFlg: false,
      title: '',
      buttons: [],
      type: '',
      popupMessage: ''
    }
  },

  computed: {
    ...mapGetters({
      getLargeClass: 'medicineLargeClasses/getDataByOriginalId',
      getMediumClass: 'medicineMediumClasses/getDataByOriginalId',
      getSmallClass: 'medicineSmallClasses/getDataByOriginalId',
      getMedicine: 'medicines/getDataByOriginalId',
      getMasterDatum: 'master/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    displayData() {
      const medicine = this.getMedicine(this.originalId)
      if (medicine) {
        const smallClass = this.getSmallClass(
          medicine.medicineSmallClassOriginalId
        )
        const mediumClass = this.getMediumClass(
          smallClass.medicineMediumClassOriginalId
        )
        const largeClass = this.getLargeClass(
          mediumClass.medicineLargeClassOriginalId
        )
        return {
          ...medicine,
          largeClassName: largeClass.name,
          mediumClassName: mediumClass.name,
          smallClassName: smallClass.name,
          priceRate: toZeroSuppress(medicine.priceRate, true),
          defaultVolume: toZeroSuppress(medicine.defaultVolume, true),
          unitType: this.getMasterDatum('unitTypes', medicine.unitTypeId).name,
          taxExemptFlg: toCircleOrCross(medicine.taxExemptFlg),
          anesthesiaFlg: toCircleOrCross(medicine.anesthesiaFlg),
          anicomFlg: toCircleOrCross(medicine.anicomFlg),
          ipetFlg: toCircleOrCross(medicine.ipetFlg),
          vaccine:
            this.getMasterDatum('vaccines', medicine.vaccineId)?.name ||
            '非ワクチン',
          showFlg: toCircleOrCross(medicine.showFlg)
        }
      } else {
        return {}
      }
    }
  },

  methods: {
    goToListPage() {
      const medicine = this.getMedicine(this.originalId)
      const query = this.mixinMakeMedicineRouteQuery(
        'small',
        medicine.medicineSmallClassOriginalId
      )
      this.$router.push({ name: 'medicines', query })
    },
    goToEditPage() {
      this.$router.push({ name: 'medicine-edit', params: this.originalId })
    },
    openDeletePopup() {
      this.type = 'alert'
      this.title = '注意'
      this.buttons = ['削除しない', '削除する']
      this.popupMessage = '削除してもよろしいですか？'
      this.popupFlg = true
    },
    cancelDelete() {
      this.popupFlg = false
    },
    async decideDelete() {
      this.waitFlg = true
      const id = this.getMedicine(this.originalId).id
      const result = await this.$store.dispatch('medicines/delete', id)
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '削除しました'
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage =
          result === 'used in treatmentSetItem'
            ? '診療項目セットで使用されているため削除できません'
            : result === 'used in estimateTreatmentItem'
            ? '見積書で使用されているため削除できません'
            : '削除に失敗しました'
      }
      this.waitFlg = false
    },
    closePopup() {
      this.popupFlg = false
      this.goToListPage()
    }
  }
}
</script>

<style lang="scss" scoped>
.medicine-show {
  width: 100%;
  min-width: 614px;
  text-align: left;
  > .list-link {
    margin-left: -26px;
  }
  > .title {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
    margin-top: 20px;
  }
  > .contents {
    margin-top: 20px;
    padding-bottom: 50px;
    > .button-area {
      display: flex;
      justify-content: flex-end;
      > .button {
        margin-left: 20px;
      }
    }
    > .basic-items-area {
      > .heading {
        font-size: 15px;
        font-weight: bold;
        border-bottom: 1px solid #{$light-grey};
        padding-bottom: 20px;
        margin-top: 20px;
      }
      > .basic-items {
        margin-top: 25px;
      }
    }
  }
}
</style>
