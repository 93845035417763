<template>
  <div class="medicine-class-input-form">
    <div class="row name">
      <text-input-form
        :requiredFlg="true"
        :placeholder="`${maxlength}文字まで`"
        :maxlength="maxlength"
        :styles="{ width: '350px' }"
        v-model="editClass.name"
        >{{ fieldName }}</text-input-form
      >
    </div>
    <div class="row showFlg">
      <select-box-form
        :selectData="selectData"
        v-model.number="editClass.showFlg"
        >表示設定</select-box-form
      >
    </div>
  </div>
</template>

<script>
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'

export default {
  name: 'MedicineClassInputForm',

  components: {
    TextInputForm,
    SelectBoxForm
  },

  props: {
    editClass: { type: Object },
    target: { type: String }
  },

  data() {
    return {
      selectData: [
        { id: 0, name: '✕' },
        { id: 1, name: '〇' }
      ]
    }
  },

  computed: {
    fieldName() {
      return this.target === 'medicine-large-classes'
        ? '大分類名'
        : this.target === 'medicine-medium-classes'
        ? '中分類名'
        : '小分類名'
    },
    maxlength() {
      return this.target === 'medicine-small-classes' ? 50 : 30
    }
  }
}
</script>

<style lang="scss" scoped>
.medicine-class-input-form {
  > .row ~ .row {
    margin-top: 25px;
  }
}
</style>
