<template>
  <div class="medicine-new">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="title">薬剤物品登録</div>
      <div class="contents">
        <div class="content-wrap">
          <div class="heading">基本項目</div>
          <div class="content">
            <medicine-input-form
              class="input-form"
              :largeClass="largeClass"
              :largeClassesSelectData="largeClassesSelectData"
              :mediumClass="mediumClass"
              :mediumClassesSelectData="mediumClassesSelectData"
              :smallClass="smallClass"
              :smallClassesSelectData="smallClassesSelectData"
              :medicine="medicine"
              :vaccineTypeId="vaccineTypeId"
              @select-input="handleSelectInput"
              @text-input="handleTextInput"
            />
          </div>
        </div>
        <div class="button-wrap">
          <base-button-medium-white class="button" @click="goToListPage"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || waitFlg"
            @click="createData"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <announce-popup
        v-if="popupFlg"
        :type="type"
        :title="title"
        :buttons="buttons"
        @close="closePopup"
        >{{ popupMessage }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import MedicineInputForm from '@/components/parts/organisms/MedicineInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import SlideListRoute from '@/components/mixins/SlideListRoute'

export default {
  name: 'MedicineNew',

  components: {
    MedicineInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference, SlideListRoute],

  props: {
    from: { type: String },
    id: { type: Number }
  },

  data() {
    return {
      largeClass: { id: 0, originalId: 0, name: '' },
      mediumClass: { id: 0, originalId: 0, name: '' },
      smallClass: { id: 0, originalId: 0, name: '' },
      medicine: {
        id: 0,
        originalId: 0,
        medicineSmallClassOriginalId: 0,
        name: '',
        priceRate: 0,
        defaultVolume: 0,
        unitTypeId: 1,
        taxExemptFlg: 0,
        memo: '',
        anesthesiaFlg: 0,
        anicomFlg: 0,
        ipetFlg: 0,
        vaccineId: 0,
        showFlg: 1
      },
      vaccineTypeId: 0,
      largeClassesSelectData: [],
      mediumClassesSelectData: [],
      smallClassesSelectData: [],
      waitFlg: false,
      noDataFlg: false,
      popupFlg: false,
      type: '',
      title: '',
      buttons: ['閉じる'],
      popupMessage: ''
    }
  },

  computed: {
    ...mapGetters({
      largeClassesSelectDataZero: 'medicineLargeClasses/selectDataZero',
      mediumClassesByLargeOriginalId:
        'medicineMediumClasses/getDataByMedicineLargeClassOriginalId',
      smallClassesByMediumOriginalId:
        'medicineSmallClasses/getDataByMedicineMediumClassOriginalId',
      getLargeClassByOriginalId: 'medicineLargeClasses/getDataByOriginalId',
      getMediumClassByOriginalId: 'medicineMediumClasses/getDataByOriginalId',
      getLargeClassById: 'medicineLargeClasses/getDataById',
      getMediumClassById: 'medicineMediumClasses/getDataById',
      getSmallClassById: 'medicineSmallClasses/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },

  created() {
    if (this.from === 'large') {
      this.largeClass = this.getLargeClassById(this.id)
      this.mediumClassesSelectData = this.makeSelectData(
        this.mediumClassesByLargeOriginalId(this.largeClass.originalId)
      )
      this.smallClassesSelectData = this.makeSelectData(
        this.smallClassesByMediumOriginalId(this.mediumClass.originalId)
      )
    } else if (this.from === 'medium') {
      this.mediumClass = this.getMediumClassById(this.id)
      this.largeClass = this.getLargeClassByOriginalId(
        this.mediumClass.medicineLargeClassOriginalId
      )
      this.smallClassesSelectData = this.makeSelectData(
        this.smallClassesByMediumOriginalId(this.mediumClass.originalId)
      )
    } else if (this.from === 'small') {
      this.smallClass = this.getSmallClassById(this.id)
      this.mediumClass = this.getMediumClassByOriginalId(
        this.smallClass.medicineMediumClassOriginalId
      )
      this.largeClass = this.getLargeClassByOriginalId(
        this.mediumClass.medicineLargeClassOriginalId
      )
    } else {
      this.largeClassesSelectData = _.cloneDeep(this.largeClassesSelectDataZero)
      this.mediumClassesSelectData = this.makeSelectData(
        this.mediumClassesByLargeOriginalId(this.largeClass.originalId)
      )
      this.smallClassesSelectData = this.makeSelectData(
        this.smallClassesByMediumOriginalId(this.mediumClass.originalId)
      )
    }
    this.mixinInputData = {
      largeClass: this.largeClass,
      mediumClass: this.mediumClass,
      smallClass: this.smallClass,
      medicine: this.medicine
    }
    this.mixinSetInitialData()
  },

  methods: {
    goToListPage() {
      const mixinArguments =
        this.from === 'large'
          ? ['large', this.largeClass.originalId]
          : this.from === 'medium'
          ? ['medium', this.mediumClass.originalId]
          : this.from === 'small'
          ? ['small', this.smallClass.originalId]
          : []
      const query = this.mixinMakeMedicineRouteQuery(...mixinArguments)
      this.$router.push({ name: 'medicines', query })
    },
    makeSelectData(data) {
      const selectData = [{ id: 0, name: '新規分類名' }].concat(
        (data || []).map(v => {
          return { id: v.id, name: v.name }
        })
      )
      return _.cloneDeep(selectData)
    },
    async createData() {
      this.waitFlg = true
      const result = await this.$store.dispatch('medicines/create', {
        medicineLargeClass: this.largeClass,
        medicineMediumClass: this.mediumClass,
        medicineSmallClass: this.smallClass,
        medicine: this.medicine
      })
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '新規登録しました'
        this.mixinSetInitialData()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        if (result === 'no data in clinic') {
          this.popupMessage =
            '登録に失敗しました。\n薬剤物品分類は既に削除されています。'
          this.noDataFlg = true
        } else if (result === 'no medicineSmallClass') {
          this.noDataFlg = true
          this.popupMessage =
            '登録に失敗しました。\n薬剤物品小分類は別端末で編集または削除されました。'
        } else if (result === 'no medicineMediumClass') {
          this.noDataFlg = true
          this.popupMessage =
            '登録に失敗しました。\n薬剤物品中分類は別端末で編集または削除されました。'
        } else if (result === 'no medicineLargeClass') {
          this.noDataFlg = true
          this.popupMessage =
            '登録に失敗しました。\n薬剤物品大分類は別端末で編集または削除されました。'
        } else {
          this.popupMessage = '登録に失敗しました'
        }
      }
      this.waitFlg = false
      this.popupFlg = true
    },
    closePopup() {
      this.popupFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.goToListPage()
      } else if (this.noDataFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.goToListPage()
      }
      this.title = ''
      this.type = ''
      this.popupMessage = ''
    },
    handleSelectInput(e) {
      if (e.field === 'largeClass') {
        if (e.val === 0) {
          this.largeClass = { id: 0, originalId: 0, name: '' }
          this.mediumClass = { id: 0, originalId: 0, name: '' }
          this.smallClass = { id: 0, originalId: 0, name: '' }
          this.mixinInputData.largeClass = this.largeClass
          this.mixinInputData.mediumClass = this.mediumClass
          this.mixinInputData.smallClass = this.smallClass
        } else {
          const target = this.getLargeClassById(e.val)
          Object.keys(target).forEach(key => {
            this.largeClass[key] = target[key]
          })
          this.mediumClass = { id: 0, originalId: 0, name: '' }
          this.smallClass = { id: 0, originalId: 0, name: '' }
        }
        this.mediumClassesSelectData = this.makeSelectData(
          this.mediumClassesByLargeOriginalId(this.largeClass.originalId)
        )
        this.smallClassesSelectData = this.makeSelectData(
          this.smallClassesByMediumOriginalId(this.mediumClass.originalId)
        )
      } else if (e.field === 'mediumClass') {
        if (e.val === 0) {
          this.mediumClass = { id: 0, originalId: 0, name: '' }
          this.smallClass = { id: 0, originalId: 0, name: '' }
          this.mixinInputData.mediumClass = this.mediumClass
          this.mixinInputData.smallClass = this.smallClass
        } else {
          const target = this.getMediumClassById(e.val)
          Object.keys(target).forEach(key => {
            this.mediumClass[key] = target[key]
          })
          this.smallClass = { id: 0, originalId: 0, name: '' }
        }
        this.smallClassesSelectData = this.makeSelectData(
          this.smallClassesByMediumOriginalId(this.mediumClass.originalId)
        )
      } else {
        if (e.val === 0) {
          this.smallClass = { id: 0, originalId: 0, name: '' }
          this.mixinInputData.smallClass = this.smallClass
        } else {
          const target = this.getSmallClassById(e.val)
          Object.keys(target).forEach(key => {
            this.smallClass[key] = target[key]
          })
        }
      }
    },
    handleTextInput(e) {
      if (e.field === 'largeClass') {
        this.largeClass.name = e.val
      } else if (e.field === 'mediumClass') {
        this.mediumClass.name = e.val
      } else if (e.field === 'smallClass') {
        this.smallClass.name = e.val
      } else {
        if (e.field === 'vaccineTypeId') {
          this.medicine.vaccineId = 0
          this.vaccineTypeId = e.val
        } else {
          this.medicine[e.field] = e.val
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.medicine-new {
  width: 100%;
  text-align: left;
  > .validation-observer {
    > .title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
      width: 100%;
      min-width: 700px;
    }
    > .contents {
      margin-top: 20px;
      padding-bottom: 50px;
      > .content-wrap {
        > .heading {
          font-size: 15px;
          font-weight: bold;
          border-bottom: 1px solid #{$light-grey};
          padding-bottom: 20px;
          min-width: 700px;
        }
        > .content {
          margin-top: 25px;
          > .input-form {
            width: 600px;
          }
        }
      }
      > .button-wrap {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
