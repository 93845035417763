<template>
  <div class="vaccine-input-form">
    <div class="heading" :style="{ height: headingHeight }">
      <div class="text">
        <slot></slot>
      </div>
      <div class="required" v-if="requiredFlg">必須</div>
    </div>
    <div class="body">
      <base-select-box
        class="select-box"
        :value="vaccineTypeId"
        :selectData="selectData"
        :styles="styles"
        @input="selectVaccineType"
      />
      <vaccine-check-list
        class="check-list"
        v-if="vaccineTypeId !== 0"
        :checkedId="vaccineId"
        :vaccineTypeId="vaccineTypeId"
        @check="checkVaccine"
      ></vaccine-check-list>
    </div>
  </div>
</template>

<script>
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import VaccineCheckList from '@/components/parts/molecules/VaccineCheckList'

export default {
  name: 'VaccineInputForm',

  components: {
    BaseSelectBox,
    VaccineCheckList
  },

  props: {
    requiredFlg: { type: Boolean, default: false },
    selectData: { type: Array },
    styles: { type: Object },
    vaccineTypeId: { type: [String, Number], default: 0 },
    vaccineId: { type: Number, default: 0 }
  },

  computed: {
    headingHeight() {
      return this.styles ? (this.styles.height ? this.styles.height : '') : ''
    }
  },

  methods: {
    selectVaccineType(val) {
      this.$emit('select', val)
    },
    checkVaccine(val) {
      this.$emit('check', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.vaccine-input-form {
  display: flex;
  align-items: flex-start;
  > .heading {
    display: flex;
    align-items: center;
    height: 33px;
    width: 40%;
    font-size: 15px;
    > .text {
      display: flex;
    }
    > .required {
      margin-left: 10px;
      font-size: 13px;
      color: #{$tomato};
    }
  }
  > .body {
    width: 60%;
    > .check-list {
      margin-top: 15px;
    }
  }
}
</style>
